import { PartyInfo, PartyMessage } from "./PartyTypes";

export async function getAllParties() {
  try {
    const response = await fetch(process.env.REACT_APP_MOVEMENT_SERVER + "/api/party/parties", {
      method: 'GET'
    });

    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }

    const data: string = await response.text();
    console.log(data)

    return { data };
  } catch (error) {
    let errorMessage = 'An unknown error occurred';
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    return { data: null, error: errorMessage };
  }
}

export async function registerSession() {
  try {
    const response = await fetch(process.env.REACT_APP_MOVEMENT_SERVER + "/api/party/session", {
      method: 'POST'
    });

    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }

    const data: string = await response.text();
    console.log(data)

    return { data };
  } catch (error) {
    let errorMessage = 'An unknown error occurred';
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    return { data: null, error: errorMessage };
  }
}

export async function start(body: PartyMessage) {
  try {
    const response = await fetch(process.env.REACT_APP_MOVEMENT_SERVER + "/api/party/start", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });

    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }

    const data: PartyInfo = await response.json();
    console.log(data)

    return { data };
  } catch (error) {
    let errorMessage = 'An unknown error occurred';
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    return { data: null, error: errorMessage };
  }
}

export async function access(body: PartyInfo) {
  try {
    const response = await fetch(process.env.REACT_APP_MOVEMENT_SERVER + "/api/party/access", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });

    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }

    const data: PartyInfo = await response.json();
    console.log(data)

    return { data };
  } catch (error) {
    let errorMessage = 'An unknown error occurred';
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    return { data: null, error: errorMessage };
  }
}

export async function invite(body: PartyMessage) {
  try {
    const response = await fetch(process.env.REACT_APP_MOVEMENT_SERVER + "/api/party/invite", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });

    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }

    const data: PartyInfo = await response.json();
    console.log(data)

    return { data };
  } catch (error) {
    let errorMessage = 'An unknown error occurred';
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    return { data: null, error: errorMessage };
  }
}

export async function inviteAccept(body: PartyMessage) {
  try {
    const response = await fetch(process.env.REACT_APP_MOVEMENT_SERVER + "/api/party/invite/accept", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });

    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }

    const data: PartyInfo = await response.json();
    console.log(data)

    return { data };
  } catch (error) {
    let errorMessage = 'An unknown error occurred';
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    return { data: null, error: errorMessage };
  }
}

export async function inviteReject(body: PartyMessage) {
  try {
    const response = await fetch(process.env.REACT_APP_MOVEMENT_SERVER + "/api/party/invite/reject", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });

    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }

    const data: PartyInfo = await response.json();
    console.log(data)

    return { data };
  } catch (error) {
    let errorMessage = 'An unknown error occurred';
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    return { data: null, error: errorMessage };
  }
}


export async function joinRequestSend(body: PartyMessage) {
  try {
    const response = await fetch(process.env.REACT_APP_MOVEMENT_SERVER + "/api/party/join-request/send", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });

    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }

    const data: PartyInfo = await response.json();
    console.log(data)

    return { data };
  } catch (error) {
    let errorMessage = 'An unknown error occurred';
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    return { data: null, error: errorMessage };
  }
}

export async function joinRequestAccept(body: PartyMessage) {
  try {
    const response = await fetch(process.env.REACT_APP_MOVEMENT_SERVER + "/api/party/join-request/accept", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });

    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }

    const data: PartyInfo = await response.json();
    console.log(data)

    return { data };
  } catch (error) {
    let errorMessage = 'An unknown error occurred';
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    return { data: null, error: errorMessage };
  }
}

export async function joinRequestReject(body: PartyMessage) {
  try {
    const response = await fetch(process.env.REACT_APP_MOVEMENT_SERVER + "/api/party/join-request/reject", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });

    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }

    const data: PartyInfo = await response.json();
    console.log(data)

    return { data };
  } catch (error) {
    let errorMessage = 'An unknown error occurred';
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    return { data: null, error: errorMessage };
  }
}

export async function leave(body: PartyMessage) {
  try {
    const response = await fetch(process.env.REACT_APP_MOVEMENT_SERVER + "/api/party/leave", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });

    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }

    const data: string = await response.json();
    console.log(data)

    return { data };
  } catch (error) {
    let errorMessage = 'An unknown error occurred';
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    return { data: null, error: errorMessage };
  }
}

export async function leaveAll(body: PartyMessage) {
  try {
    const response = await fetch(process.env.REACT_APP_MOVEMENT_SERVER + "/api/party/leave-all", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });

    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }

    const data: string = await response.json();
    console.log(data)

    return { data };
  } catch (error) {
    let errorMessage = 'An unknown error occurred';
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    return { data: null, error: errorMessage };
  }
}

export async function kick(body: PartyMessage) {
  try {
    const response = await fetch(process.env.REACT_APP_MOVEMENT_SERVER + "/api/party/kick", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });

    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }

    const data: PartyInfo = await response.json();
    console.log(data)

    return { data };
  } catch (error) {
    let errorMessage = 'An unknown error occurred';
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    return { data: null, error: errorMessage };
  }
}

export async function mute(body: PartyMessage) {
  try {
    const response = await fetch(process.env.REACT_APP_MOVEMENT_SERVER + "/api/party/mute", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });

    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }

    const data: PartyInfo = await response.json();
    console.log(data)

    return { data };
  } catch (error) {
    let errorMessage = 'An unknown error occurred';
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    return { data: null, error: errorMessage };
  }
}

export async function solo(body: PartyMessage) {
  try {
    const response = await fetch(process.env.REACT_APP_MOVEMENT_SERVER + "/api/party/solo", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });

    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }

    const data: PartyInfo = await response.json();
    console.log(data)

    return { data };
  } catch (error) {
    let errorMessage = 'An unknown error occurred';
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    return { data: null, error: errorMessage };
  }
}

export async function end(body: PartyMessage) {
  try {
    const response = await fetch(process.env.REACT_APP_MOVEMENT_SERVER + "/api/party/end", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });

    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }

    const data: PartyInfo = await response.json();
    console.log(data)

    return { data };
  } catch (error) {
    let errorMessage = 'An unknown error occurred';
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    return { data: null, error: errorMessage };


  }
}

export async function callStatus(body: PartyMessage) {
  try {
    const response = await fetch(process.env.REACT_APP_MOVEMENT_SERVER + "/player-connected", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });

    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }

    const data: PartyInfo = await response.json();
    console.log(data)

    return { data };
  } catch (error) {
    let errorMessage = 'An unknown error occurred';
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    return { data: null, error: errorMessage };
  }
}

