import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import {store} from "./store/store";
import {createTheme, ThemeProvider} from "@mui/material";

const theme = createTheme({
  palette: {
      primary: {
          main: "#4C9CB9",
      },
      secondary: {
          main: "#F4BA41"
      },
      error: {
          main: "#EC8B33"
      },
      background: {
          paper: "#262C3F"
      },
      text: {
          primary: "#fff"
      },
      action: {
          disabled: "#eae9e9"
      }
  }
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <App />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
