import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { WebSocketClient } from "../../components/Comms/WebSocketClient";

export interface Disconnection {
    timestamp: string,
    playerId: string
}

interface ConnectionState{
    websocket: WebSocketClient | null
    disconnection: Disconnection[]
}

const initialState: ConnectionState = {
    websocket: process.env.REACT_APP_WEBSOCKET_SERVER ? new WebSocketClient(process.env.REACT_APP_WEBSOCKET_SERVER, '') : null,
    disconnection: []
}

export const connectionSlice = createSlice({
    name: 'connection',
    initialState,
    reducers: {
        setConnection: (state, action: PayloadAction<Disconnection>) => {
            state.disconnection.push(action.payload)
        },
        setWebSocket: (state, action: PayloadAction<WebSocketClient>) => {
            state.websocket = action.payload
        } 
    }
})

export const {setConnection, setWebSocket} = connectionSlice.actions
export const selectConnection = (state: RootState) => state.connection
export default connectionSlice.reducer