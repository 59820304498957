import { Client, IPublishParams, StompSubscription } from '@stomp/stompjs'
import { InitialisationObject } from "../../App";
import { getTimestamp } from './Timestamp';

interface ITimestampedParams extends IPublishParams {
    timestamp: string
}

export class WebSocketClient {

    private client: Client
    private onFirstSub: Function = () => { console.log("No onFirstSubscription callback configured") };
    private onMessage: Function = () => { console.log("No onMessage callback configured") };
    private onAvatarUpdate: Function = () => { console.log("No onAvatarUpdate callback configured") }
    private onDisconnect: Function = () => { console.log("No onDisconnect callback configured") };
    private onChat: Function = () => { console.log("No onChat callback configured") };
    private onVoiceRoomcast: Function = () => { console.log("No onVoiceRoomcast callback configured") };
    private onUserQueuePartyMessage: Function = () => { console.log("No onUserQueuePartyMessage callback configured") }
    private currentRoom: string;
    private sessionID: string = "";

    constructor(host: string, room: string) {
        this.currentRoom = room
        this.client = new Client({
            brokerURL: host, connectHeaders: { joinRoom: this.currentRoom }, onConnect: (frame) => {
                console.log(frame)
                this.subscribeToUser(this.onFirstSub, this.onMessage, this.onAvatarUpdate, this.onDisconnect, this.onChat, this.onVoiceRoomcast, this.onUserQueuePartyMessage);
            },
        })
        this.client.activate()
    }
    addSubscription(onFirstSubscribe: Function, onMessage: Function, onDisconnect: Function) {
        this.onFirstSub = onFirstSubscribe;
        this.onMessage = onMessage;
        this.onDisconnect = onDisconnect;
    }
    addUpdateAvatarSubscription(onAvatarUpdate: Function) {
        this.onAvatarUpdate = onAvatarUpdate;
    }
    addChatSubscription(onChat: Function) {
        this.onChat = onChat
    }
    addVoiceRoomcastSubscription(onVoiceRoomcast: Function){
        this.onVoiceRoomcast = onVoiceRoomcast
    }
    addUserQueuePartySubscription(onUserQueuePartyMessage: Function){
        this.onUserQueuePartyMessage = onUserQueuePartyMessage
    }
    private subscribeToMovementsTopic(onMessage: Function) {
        this.subscribeToRoom('/topic/player-movement', onMessage);
    }
    private subscribeToAvatarUpdateTopic(onAvatarUpdate: Function) {
        this.subscribeToRoom('/topic/update-avatar', onAvatarUpdate);
    }
    private subscribeToDisconnects(onDisconnect: Function) {
        this.subscribeToRoom('/topic/disconnect-user', onDisconnect);
    }
    private subscribeToChat(onChat: Function) {
        //this.subscribeToRoom('/topic/chat', onChat)
        this.subscribeToGlobal('/topic/chat', onChat)
    }
    private subscribeToRoom(destination: string, onSubscribe: Function) {
        console.log("Subscribing to " + destination + "/" + this.currentRoom);
        this.client.subscribe(destination + "/" + this.currentRoom, message => onSubscribe(message));
    }
    private subscribeToVoiceRoomCast(onMessage: Function){
        this.client.subscribe("/topic/voice", message => onMessage(message))
    }
    private subscribeToUserQueueParty(onUserQueuePartyMessage: Function){
        this.client.subscribe("/user/queue/party", message => onUserQueuePartyMessage(message))
    }
    private subscribeToGlobal(destination: string, onSubscribe: Function) {
        console.log("Subscribing to " + destination);
        this.client.subscribe(destination, message => onSubscribe(message));
    }

    subscribeToUser(onFirstSubscribe: Function, onMessage: Function, onAvatarUpdate: Function, onDisconnect: Function, onChat: Function, voiceMessage: Function, onUserQueuePartyMessage:Function) {
        console.log("Subscribing to queue/initialise. Client Status: " + this.client.connected);
        var subscription: StompSubscription = this.client.subscribe("/user/queue/initialise", message => {
            const initialisationObject: InitialisationObject = JSON.parse(message.body)
            this.currentRoom = initialisationObject.room
            this.sessionID = initialisationObject.sessionId
            onFirstSubscribe(message)
            this.subscribeToMovementsTopic(onMessage)
            this.subscribeToAvatarUpdateTopic(onAvatarUpdate)
            this.subscribeToDisconnects(onDisconnect)
            this.subscribeToChat(onChat)
            this.subscribeToVoiceRoomCast(voiceMessage)
            this.subscribeToUserQueueParty(onUserQueuePartyMessage)
        })
    }

    publishObjToMovementTopic(message: object) {
        let msg:ITimestampedParams = { destination: "/app/player-movement", body: JSON.stringify(message), timestamp: getTimestamp() }
        this.client.publish(msg)
    }
    publishObjToUpdateAvatarTopic(message: object) {
        console.log("publishing: %s", JSON.stringify(message))
        let msg:ITimestampedParams = { destination: "/app/update-avatar", body: JSON.stringify(message), timestamp: getTimestamp() }
        this.client.publish({ destination: "/app/update-avatar", body: JSON.stringify(message)})
    }
    publishToGlobalChat(message: object) {
        this.client.publish({ destination: "/app/send-global-chat-message", body: JSON.stringify(message)})
    }
    publishObjToVoiceRoomcastTopic(message: string) {
        let msg:ITimestampedParams = { destination: "/app/voice-roomcast", body: JSON.stringify(message), timestamp: getTimestamp() }
        this.client.publish(msg)
    }

    disconnect() {
        this.client.deactivate();
    }

    getSessiondId(){
        return this.sessionID;
    }
}