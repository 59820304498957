import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useSpring, animated } from '@react-spring/web';
import { Avatar } from '@readyplayerme/visage';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setUnityMessage } from '../../../store/Reducers/Messages';
import { getTimestamp } from '../../Comms/Timestamp';
import { PartyMessage, PartyAccessType, PartyStatus, PartyCallStatus } from './PartyTypes';
import { getAllParties, start } from './RestCalls';
import { WebSocketClient } from '../../Comms/WebSocketClient';
import TextField from '@mui/material/TextField';
import { setAvailableParties } from '../../../store/Reducers/party';

interface FadeProps {
  children: React.ReactElement;
  in?: boolean;
  onClick?: any;
  onEnter?: (node: HTMLElement, isAppearing: boolean) => void;
  onExited?: (node: HTMLElement, isAppearing: boolean) => void;
  ownerState?: any;
}

export const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null as any, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null as any, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

export type StyleType = {
    position: string;
    top: string;
    left: string;
    transform: string;
    width: string;
    boxShadow: number;
    p: number;
  };

export const style: StyleType = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '20%',
  boxShadow: 24,
  p: 4,
};

type Props = {
    open: boolean,
    setOpen: Function,
    websocket: WebSocketClient
}
export const SetupPartyModal: React.FC<Props> = ({open,setOpen, websocket}) =>  {
  
  const handleClose = () => setOpen(false);
  const [name,setName] = React.useState("")
  const { playerLoggedIn } = useAppSelector(state => state.players)
  const dispatch = useAppDispatch()

  const triggerStartParty = () => {
    setOpen(false)
    let partyMessage: PartyMessage = {
        fromPlayerId: playerLoggedIn.playerId,
        toPlayerId: "",
        partyInfo: {
            timestamp: getTimestamp(),
            partyId: "",
            hostPlayerId: null,
            partyName: name,
            accessType: PartyAccessType.PUBLIC,
            partyPeople: [],
            partyStatus: PartyStatus.NOT_STARTED,
            hostPeerId: "",
            hostPartyCallStatus: PartyCallStatus.NOT_CONNECTED,
            partyActivities: []
        },
        fromPlayerToPeerId: "",
        textMessage: "",
        sessionId: websocket.getSessiondId()
    }
    start(partyMessage).then(res => {
        console.log(res)
        getParties()
    })
};

const getParties = () => {
  getAllParties().then(res => {
      console.log(res)
      if (res.data) {
          dispatch(setAvailableParties(JSON.parse(res.data)))
      }
  })
};

  return (
    <div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={open}>
          <Box component="div"  className='card dropDownCard' sx={style}>
                <div>
                  <h3 style={{margin: 0}}>Set Party Name</h3>
                  <br></br><br></br>
                  <TextField 
                        id="outlined-basic" 
                        label="Type here.." 
                        variant="outlined" 
                        value={name} 
                        onChange={(e) => {
                            setName(e.target.value)}}
                        fullWidth
                        color='error'
                        InputLabelProps={{style: { color: 'white' }}}
                    /> 
                    <br></br><br></br>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <button onClick={() => setOpen(false)} className="customButton">
                            Cancel
                        </button>
                        <button onClick={() => triggerStartParty()} className="customButton">
                            Start Party
                        </button>
                    </div>
                </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}