import { PropsWithChildren, useEffect, useRef } from "react";
import { createPortal } from "react-dom";

export function NewWindow({children}: PropsWithChildren) {
    const containerRef = useRef<HTMLDivElement>(document.createElement("div"));
    const windowRef = useRef<Window | null>(null);

    useEffect(() => {
        windowRef.current = window.open(
            "","", `width=1000, height=600,left=800,top=400`
        );
        if(windowRef.current){

            windowRef.current?.document.body.appendChild(
                containerRef.current
            );
            const newDoc = windowRef.current.document
            newDoc.body.style.margin = '0';
            newDoc.body.style.padding = '0';

            const container = containerRef.current;
            container.style.backgroundColor = "black"; 
            container.style.color = "green"; 
            container.style.textAlign = "center"; 
            container.style.padding = '0';
            container.style.overflowY = 'scroll';
            container.style.height = '100vh';
        }
        return () => {
            windowRef.current?.close();
        }
    }, [])

    return createPortal(children, containerRef.current)
}