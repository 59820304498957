import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const CPUChart: React.FC = () => {
  const [data, setData] = useState<ChartData<'line'>>({
    labels: [],
    datasets: []
  });
  const [max, setMax] = useState<number>(100)
  const [title, setTitle] = useState<string>("CPU Usage Over Time")

  const fetchCpuData = () => {
    const labels = ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00'];
    const cpuUsage = [10, 30, 45, 50, 20, 60, 75]; 
    setMax(100)
    setTitle("CPU Usage Over Time")
    setData({
      labels,
      datasets: [
        {
          label: "CPU Usage (%)",
          data: cpuUsage,
          fill: false,
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1
        }
      ]
    });
  };

  const fetchSwapData = () => {
    const labels = ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00'];
    const cpuUsage = [40, 30, 45, 56, 10, 70, 100]; 
    setMax(100)
    setTitle("Swap File Usage")
    setData({
      labels,
      datasets: [
        {
          label: "Swap Usage (%)",
          data: cpuUsage,
          fill: false,
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1
        }
      ]
    });
  };

  const fetchIOData = () => {
    const labels = ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00'];
    const cpuUsage = [1234,2133, 1235, 3210, 1230, 2460, 1125]; 
    setMax(5000)
    setTitle("I/O data")
    setData({
      labels,
      datasets: [
        {
          label: "I/O",
          data: cpuUsage,
          fill: false,
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1
        }
      ]
    });
  };

  useEffect(() => {
    fetchCpuData();
  }, []);

  const options: ChartOptions<'line'> = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top'
      },
      title: {
        display: true,
        text: title,
        color: '#fff'
      }
    },
    scales: {
        x: {
          ticks: {
            color: '#fff' 
          },
          grid: {
            color: 'rgba(75, 192, 192, 0.2)' 
          }
        },
        y: {
          beginAtZero: true,
          max: max,
          ticks: {
            color: '#fff' 
          },
          grid: {
            color: 'rgba(75, 192, 192, 0.2)' 
          }
        }
      }
  };

  return <div>
            <Line data={data} options={options} />
            <button 
                className='chartButton'
                onClick={() => fetchCpuData()}
                >CPU</button>
            <button 
                className='chartButton'
                onClick={() => fetchIOData()}
                >IO</button>
            <button 
                className='chartButton'
                onClick={() => fetchSwapData()}
                >SWAP</button>
        </div>
};

export default CPUChart;
