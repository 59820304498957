import React, {useState, useEffect} from "react";
import {WebSocketClient} from "./WebSocketClient";
import { IMessage } from "@stomp/stompjs";
import { buffer } from "stream/consumers";
import { useAppSelector } from "../../store/hooks";

interface Props {
}
interface ActivityLog {
    txt: string
}
const ActivityConsole : React.FC<Props> = () => {

    const { messages } = useAppSelector(state => state.messages)
 
    function reverseHistory(){
        let currentMessages = [...messages]
        return currentMessages.reverse()
    }

    return (<div>
                <div className="console-textarea">
                    {
                        reverseHistory().map((item, index )=> {
                        return <p key={`message${index}`}>{item}</p>
                        })
                    }</div>
            </div>);
}

export default ActivityConsole;