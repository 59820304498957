import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useAppSelector } from '../../store/hooks';
import { Fade, style } from '../Avatar/AvatarUpdaterModal';
import CPUChart from './CPUChart';

type Props = {
    open: boolean,
    setOpen: Function
}
export const ServerStatsModel: React.FC<Props> = ({open,setOpen}) =>  {
  
  const handleClose = () => setOpen(false);
  const {serverData}= useAppSelector(state => state.servers)

  return (
    <div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={open}>
        <Box  className="card dropDownCard"  component="div" sx={{ ...style, display: 'flex', gap: '16px' }}>
            <div style={{ width: '30%', height: '90%' }}>
                <h3 style={{ margin: 0, paddingBottom: 30 }}>Server Name</h3>
                <p>Public IP: <span className='dataField'>{serverData.publicIp}</span></p>
                <p>Private IP: <span className='dataField'>{serverData.privateIp}</span></p>
                <p>CPU: <span className='dataField'>{serverData.cpuUsage}%</span></p>
                <p>IO: <span className='dataField'>{serverData.diskIo}</span></p>
                <p>Network In: <span className='dataField'>{serverData.networkIn}</span></p>
                <p>Network Out: <span className='dataField'>{serverData.networkOut}</span></p>
                <p>Transfer Quota: <span className='dataField'>{serverData.transferQuota}%</span></p>
            </div>
            <div style={{ width: '60%', height: '90%' }}>
                <h3 style={{ margin: 0 }}>History</h3>
                <CPUChart />
            </div>
            </Box>
        </Fade>
      </Modal>
    </div>
  );
}