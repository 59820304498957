import React from "react";
import { useAppSelector } from "../../store/hooks";

type Props = {
    setOpen: Function
}
export const ServerDetails: React.FC<Props> = ({setOpen}) => {

    const {serverData}= useAppSelector(state => state.servers)

    return(
        <div className="serverDetails">
            <p>Public IP: <span className='dataField'>{serverData.publicIp}</span></p>
            <p>Private IP: <span className='dataField'>{serverData.privateIp}</span></p>
            <button
                className="statsButton"
                onClick={() => setOpen(true)}
            >Stats</button>
        </div>
    )
}