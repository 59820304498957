import React, {useEffect, useRef, useState} from "react";
import {WebSocketClient} from "../Comms/WebSocketClient";
import {IMessage} from "@stomp/stompjs";
import { AvatarDefinition, setAvatarData, setPlayerLoggedin } from "../../store/Reducers/Players";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

interface Props {
    webSocketClient:WebSocketClient
    playerId: string
    avatarData: AvatarDefinition[]
}

const AvatarUpdating : React.FC<Props> = ({webSocketClient,playerId,avatarData}) => { 

    const [avatarDefinition, setAvatarDefinition] = useState<AvatarDefinition>({playerId:playerId, avatarUrl:"avatar-not-set.png", displayName:"not set"})
    const { currentSelected } = useAppSelector(state => state.players)
    const {websocket} = useAppSelector(state => state.connection)
    const avatarRef = useRef(avatarData)
    const dispatch = useAppDispatch()

    useEffect( () => {
        websocket?.addUpdateAvatarSubscription(updateAvatar)
    },[websocket] )

    useEffect(() => {
        avatarRef.current = avatarData;
    },[avatarData]) 

    const updateAvatar = (message:IMessage) => {
        console.log("Received avatar details: %s ", message.body)
        onUpdateAvatar(JSON.parse(message.body))    
    }

    const onUpdateAvatar = (avatarDefinitions: AvatarDefinition[]) => {
        const updatedAvatars = [...avatarRef.current];
        for (let avatarDefinition of avatarDefinitions) {
            const index = updatedAvatars.findIndex(avatar => avatar.playerId === avatarDefinition.playerId);
            if (index !== -1) {
                updatedAvatars[index] = avatarDefinition;
            }
            updatedAvatars.push(avatarDefinition)
        }
        dispatch(setAvatarData(updatedAvatars));
    };

    const  setDisplayName =  (event: React.ChangeEvent<HTMLInputElement>) => {
        setAvatarDefinition({displayName: event.target.value,playerId: avatarDefinition.playerId,avatarUrl:avatarDefinition.avatarUrl})
    }

    const  setAvatarUrl =  (event: React.ChangeEvent<HTMLInputElement>) => {
        setAvatarDefinition({displayName: avatarDefinition.displayName,playerId: avatarDefinition.playerId,avatarUrl:event.target.value})
    }

 
    return (<div  className="card dropDownCard" style={{width:'100%', height: '80%'}}>
                <h3 style={{margin: 0}}>Current Selected Avatar</h3>
                <div className={"playerAvatarDetails"} style={{ width: "100%", position: 'relative'}}>
                    PlayerID:<br/>
                    <input disabled className="customInput" value={currentSelected.playerId}  /> <br />
                    Display Name:<br />
                    <input disabled className="customInput" value={currentSelected.displayName} readOnly={false}  onChange={ setDisplayName }/> <br />
                    Avatar URL:<br />
                    <input disabled className="customInput"  value={currentSelected.avatarUrl} readOnly={false} onChange={ setAvatarUrl } /> <br /><br />
                </div>
            </div>
    );
}

export default AvatarUpdating;