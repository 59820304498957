import React, { useEffect, useRef, useState } from "react";
import { WebSocketClient } from "../Comms/WebSocketClient";
import { IMessage } from "@stomp/stompjs";
import { useDispatch } from "react-redux";
import { setMessageHistory } from "../../store/Reducers/Messages";
import { useAppSelector } from "../../store/hooks";
import ChatInput from "./ChatInput";
import ChatBubble from "./chatWindowComponents/ChatBubble";
import { PartyList } from "./PartyComponents/PartyList";
import { MessageHandler } from "./PartyComponents/MessageHandler";
import { v4 as uuidv4 } from 'uuid';

type Props = {
    websocket: WebSocketClient
}

export const ChatWindow: React.FC<Props> = ({ websocket }) => {

    const { messageHistory } = useAppSelector((state) => state.messages)
    const { unityMessage } = useAppSelector(state => state.messages)
    const messageRef = useRef(messageHistory)
    const [showParties, setShowParties] = useState<boolean>(false)
    const [heading, setHeading] = useState("Live Chat")
    const {userMessages,playerLoggedIn} = useAppSelector((state) => state.players)
    const dispatch = useDispatch()
    const chatContainerRef = useRef<HTMLDivElement>(null);

    const scrollToBottom = () => {
        const chatContainer = chatContainerRef.current;
        if (chatContainer) {
            chatContainer.scrollTop = chatContainer.scrollHeight;
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [messageHistory, showParties]);

    useEffect(() => {
        websocket?.addChatSubscription(updateMessage)
    }, [websocket])

    const updateMessage = (message: IMessage) => {
        console.log("Received chat message: %s ", message.body)
        formatMessages(message.body)
    }

    useEffect(() => {
        messageRef.current = messageHistory
    }, [messageHistory])

    useEffect(() => {
        console.log("Message: " + unityMessage)
        let data;
        try {
            data = JSON.parse(unityMessage);
        } catch (e) {
            console.error("Invalid JSON received:", unityMessage);
            return;
        }

        if (data.type === "unityMessage") {
            formatUnityMessages(unityMessage);
        }
    }, [unityMessage]);


    // add the incoming messages to the message history state array and attach timestamp, only store up to 500 messages
    function formatMessages(message: string) {
        let data = JSON.parse(message)
        let currentMessages: Array<any> = [...messageRef.current]

        if (currentMessages.length > 500) {

            currentMessages.shift()
            currentMessages.push({from: data.from, msg: data.msg ? data.msg : data.body, guid: data.playerId ? data.playerId : "1234", timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })})
            dispatch(setMessageHistory(currentMessages))

        } else {
            currentMessages.push({from: data.from, msg: data.msg ? data.msg : data.body, guid: data.playerId ? data.playerId : "1234", timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })})
            dispatch(setMessageHistory(currentMessages))

        }
    }

    function formatUnityMessages(message: string) {
        let data = JSON.parse(message)
        let guid = uuidv4()
        let currentMessages: Array<any> = [...messageRef.current];
        let messageUnity = { from: "System", msg: data.msg.textMessage, guid: guid, timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), partyMessage: data.msg, responded: data.responded };

        const messageExists = currentMessages.some(msg => msg.guid === messageUnity.guid);
        if (!messageExists) {
            if (currentMessages.length > 500) {
                currentMessages.shift();
            }
            currentMessages.push(messageUnity);
            dispatch(setMessageHistory(currentMessages));
        }
    }

    // check the stored user sent messages so that styling can be applied to the chat window
    function checkUserMessages(msgGuid: string) {
        for (const usrMsg of userMessages) {
            if (msgGuid=== playerLoggedIn.playerId) {
                return true;
            }
        }
        return false;
    }

    return (
        <div className="chat">
            <h2 className="chatText">{heading}</h2>
            <div className="chat-container" ref={chatContainerRef}>

                {
                    showParties ? <PartyList websocket={websocket} /> : <ul>
                        {
                            messageHistory.map((msg, index) => (

                                checkUserMessages(msg.guid) ?
                                    <ChatBubble key={`chat${index}`} index={index} msg={msg} websocket={websocket} />
                                    :
                                    <ChatBubble key={`chat${index}`} index={index} msg={msg} websocket={websocket} />
                            ))
                        }
                    </ul>
                }
            </div>
            <ChatInput websocket={websocket} setShowParties={setShowParties} showParties={showParties} />
            <MessageHandler websocket={websocket} setShowParties={setShowParties} setHeading={setHeading} />
        </div>
    )
}