import React, {useRef, useEffect, useState, useCallback} from 'react'
import { InitialMovementData} from "../../App"
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { Movement } from '../../store/Reducers/Movements';
import { AvatarDefinition, setCurrentSelected } from '../../store/Reducers/Players';
import { Pause, PlayArrow } from '@mui/icons-material';
import { setPause } from '../../store/Reducers/Messages';
interface Props {
    name: string;
    initialMovementData: InitialMovementData;
    height: number;
    width: number;
    x: number;
    y: number;
    playerId:string;
}

class PlayerIndicator implements Movement{
    element:Path2D;
    playerId: string;
    position: { x: number; y: number; z: number };
    rotation: number;
    velocity: { x: number; y: number; z: number };
    fillStyle: string;
    constructor(movement:Movement, selectedPlayer: string){
        this.playerId = movement.playerId
        this.position = movement.position
        this.rotation = movement.rotation
        this.velocity = movement.velocity
        this.element = new Path2D()
        this.fillStyle = this.playerId === selectedPlayer ? "rgb(76,156,185)" : "#F4BA41"
    }
}

const CanvasMultiplayerMovement: React.FC<Props> = ({initialMovementData, height,width }) => {

    const canvasRef = useRef<HTMLCanvasElement>(null);
    const frameRef = useRef<number>(0);
    const [players, setPlayers] = useState(new Map<string, PlayerIndicator>() )
    const {avatarData,currentSelected} = useAppSelector(state => state.players)
    const playersRef = useRef(players);
    const avatarRef = useRef(avatarData);
    const forceUpdate = React.useReducer(() => ({}), {})[1] as () => void
    const [mouseListenerCreated, setMouseListenerCreated] = useState(false);
    const [selectedPlayer, setSelectedPlayer] = useState("")
    const {disconnection} = useAppSelector(state => state.connection);
    const dispatch = useAppDispatch()
    const { movements } = useAppSelector(state => state.movements)
    const {pause} = useAppSelector(state => state.messages)

    const draw = useCallback((context: CanvasRenderingContext2D) => {
        if (context) {
            context.clearRect(0, 0, context.canvas.width, context.canvas.height);
            for (let player of players.values()) {
                const playerX = Math.min(Math.max(player.position.x * 10 + context.canvas.width / 2, 10), context.canvas.width - 10);
                const playerY = Math.min(Math.max(player.position.z * 10 + context.canvas.height / 2, 10), context.canvas.height - 10);

                context.beginPath();
                context.arc(playerX, playerY, 10, 0, 2 * Math.PI);
                context.fillStyle = player.fillStyle;
                context.fill();
                player.element = new Path2D();
                player.element.arc(playerX, playerY, 10, 0, 2 * Math.PI);
            }

            frameRef.current = requestAnimationFrame(() => draw(context));
        }
    }, [players]);

    const resizeCanvas = useCallback(() => {
        if (canvasRef.current) {
            const canvas = canvasRef.current;
            const context = canvas.getContext("2d");
            if (context) {
                canvas.width = window.innerWidth / 3;
                canvas.height = window.innerHeight / 3;
                draw(context);
            }
        }
    }, [draw]);

    useEffect(() => {
        window.addEventListener('resize', resizeCanvas);
        resizeCanvas();

        return () => {
            window.removeEventListener('resize', resizeCanvas);
            if (frameRef.current) {
                cancelAnimationFrame(frameRef.current);
            }
        };
    }, [resizeCanvas]);

    const setPointer = (context: CanvasRenderingContext2D , mouseEvent: MouseEvent) => {
        const players = playersRef.current;
        for (let player of players.values()) {
            if (player.element && context.isPointInPath(player.element, mouseEvent.offsetX, mouseEvent.offsetY)) {
                if (canvasRef.current) {
                    canvasRef.current.style.cursor = "pointer";
                }
            } else {
                if (canvasRef.current) {
                    canvasRef.current.style.cursor = "default";
                }
            }
        }
    };

    const playerClick = (context: CanvasRenderingContext2D , mouseEvent: MouseEvent) => {
        const players = playersRef.current;
        for (let player of players.values()) {
            if (player.element && context.isPointInPath(player.element, mouseEvent.offsetX, mouseEvent.offsetY)) {
                player.fillStyle = "rgb(76,156,185)";
                setSelectedPlayer(player.playerId);
                onSelectAvatar(player.playerId);
            } else if (player.fillStyle === "rgb(76,156,185)") {
                player.fillStyle = "#F4BA41";
            }
        }
    };

    useEffect(() => {
        playersRef.current = players; 
    }, [players]);

    useEffect(() => {
        avatarRef.current = avatarData;
    },[avatarData]) 

    useEffect(() => {
        console.log("Player Selected Firing")
        const players = playersRef.current;
        for (let player of players.values()) {
            if (player.element && player.playerId === currentSelected.playerId) {
                console.log(player.playerId)
                player.fillStyle = "rgb(76,156,185)";
                setSelectedPlayer(player.playerId);
                onSelectAvatar(player.playerId);
            } else if (player.fillStyle === "rgb(76,156,185)") {
                player.fillStyle = "#F4BA41";
            }
        }
    },[currentSelected])

    useEffect(() => {
        if (canvasRef.current) {
            const context = canvasRef.current.getContext("2d");
            if (context) {
                if(mouseListenerCreated === false){
                    canvasRef.current?.addEventListener("mousemove", (mouseEvent): any => {
                        setPointer(context, mouseEvent)
                    })

                    canvasRef.current?.addEventListener("click", (mouseEvent):any => {
                        playerClick(context, mouseEvent)
                    })
                    setMouseListenerCreated(true)
                }}
            }
    },[players])

    useEffect(() => {

        if (canvasRef.current) {
          
            const context = canvasRef.current.getContext("2d");
            if (context) {
                
                frameRef.current = requestAnimationFrame(() => draw(context));}
                avatarRef.current = avatarData;
        }
        return () => cancelAnimationFrame(frameRef.current);
    }, [initialMovementData,disconnection,players]);


    useEffect(() => {
        if(movements.length <= 0 ){
           players.clear()
        }
        if (movements.length > 0) {
            setPlayers(prevPlayers => {
                const newMap = new Map<string, PlayerIndicator>(prevPlayers);
                for (let movement of movements) {
                    newMap.set(movement.playerId, new PlayerIndicator(movement, selectedPlayer));
                }
                return newMap;
            });
        } 
    }, [movements]);

    useEffect(() => {
        for(let player of disconnection){
            if(player.playerId !== "") {
                if(players.delete(player.playerId)) {
                    console.log("Player removed from Player Map: %s", player)
                    setPlayers(players)
                    forceUpdate()
                }
            }
        }
    }, [disconnection])

    const onSelectAvatar = (selectedAvatarId:string) => {
        const avatars = avatarRef.current
        for(let avatar of avatars) {
            if(avatar.playerId === selectedAvatarId) {
                dispatch(setCurrentSelected(avatar))
            }
        }
    }
 
    return (
        <div className={"visualisationCanvas"}>
            <canvas ref={canvasRef} />
            <button 
                onClick={() => dispatch(setPause(!pause))}
            >
                {pause ? <PlayArrow /> : <Pause />}
            </button>
        </div>
    );
    
}

export default CanvasMultiplayerMovement