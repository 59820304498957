import {useEffect, useState} from 'react';
import './App.css';
import PositionTracking  from "./components/Movement/PositionTracking";
import CanvasMultiplayerMovement from "./components/Movement/CanvasMultiplayerMovement";
import AvatarUpdating from "./components/Avatar/AvatarUpdating";
import ActivityConsole from "./components/Comms/ActivityConsole";
import { Movement,  } from './store/Reducers/Movements';
import { AvatarDefinition, thisPlayer,setAvatarData } from './store/Reducers/Players';
import { RoomSwitcher } from './components/Comms/RoomSwitcher';
import { RoomAvatars } from './components/Avatar/RoomAvatars';
import Grid from '@mui/material/Grid';
import { useAppDispatch, useAppSelector } from './store/hooks';
import { AvatarUpdaterModal } from './components/Avatar/AvatarUpdaterModal';
import { ServerList } from './components/Servers/ServerList';
import { ServerStatsModel } from './components/Servers/ServerStatsModal';
import { NewWindow } from './components/Comms/NewWindow';
import { ChatWindow } from './components/Chat/ChatWindow';


export interface InitialisationObject {
    room:string,
    playerMovements: InitialMovementData,
    avatarDefinitions: InitialAvatarData,
    sessionId: string;
}
export type InitialMovementData = Movement[];
export type InitialAvatarData = AvatarDefinition[];


const App = () => {

    const [positionState, setPositionState] = useState<Movement>({position: { x:0,y:0,z:0}, velocity: { x:0,y:0,z:0},rotation: 1 ,playerId:thisPlayer})
    const [initialisationData, setInitialisationData] = useState<InitialMovementData>([])
    const [open, setOpen] = useState<boolean>(false);
    const [openStats, setOpenStats] = useState<boolean>(false);
    const [showConsole, setShowConsole] = useState<boolean>(false);
    const dispatch = useAppDispatch()
    const { playerLoggedIn,currentSelected, avatarData } = useAppSelector(state => state.players)
    const {websocket} = useAppSelector(state => state.connection)

    const onInitialisationDataReceived = (movementData:InitialMovementData, initialAvatarData:InitialAvatarData) => {
            setInitialisationData(movementData)
            if(!initialAvatarData.findIndex(avatar => avatar.playerId === playerLoggedIn.playerId)){
                initialAvatarData.push(playerLoggedIn)
            }
            
            dispatch(setAvatarData(initialAvatarData))

            console.log("OnInitialisationMovementDataReceived: %s", movementData.length)
            console.log("OnInitialisationAvatarData_Length: %s", initialAvatarData.length)
            console.log("OnInitialisationAvatarData: ", initialAvatarData)
    } 

  return (
    <div className="App">

        <header className="App-header" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '1%' }}>
            <img 
                src="logo.png" 
                className="rotate" 
                style={{ width: '80px', height: '80px', cursor: 'pointer' }}
                onClick={() => setOpen(true)}
            />
            <button className='consoleBtn customButton' onClick={() => setShowConsole(!showConsole)}>Console</button>
            <img 
                src={playerLoggedIn.avatarUrl} 
                style={{ width: '80px', height: '80px', cursor: 'pointer' }}
                onClick={() => setOpen(true)}
            />
        </header>
        {
            websocket && (
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 1, sm: 2, md: 12 }}>
                <Grid item xs={12} md={4} container alignItems="center" justifyContent="center">
                    <img className="card" src={currentSelected.avatarUrl.replace(".glb", ".png")} width={250} />
                </Grid>
                <Grid item xs={12} md={4} container alignItems="center" justifyContent="center">
                    <AvatarUpdating 
                        webSocketClient={websocket}
                        playerId={thisPlayer}
                        avatarData={avatarData}
                    />
                </Grid>
                <Grid item xs={12} md={4} container alignItems="center" justifyContent="center">
                    <PositionTracking
                        webSocketClient={websocket}
                        onInitialise={onInitialisationDataReceived}
                        playerId={thisPlayer}
                        initialisationData={initialisationData}
                        setPositionState={setPositionState}
                        updateInitialisationData={setInitialisationData}
                    />
                </Grid>
                <Grid item xs={12} md={4} container alignItems="center" justifyContent="center">
                    <ChatWindow websocket={websocket}/>                    
                </Grid>
                <Grid item xs={12} md={4} container alignItems="center" justifyContent="center">
                    <CanvasMultiplayerMovement 
                        name={"Canvas View"} 
                        height={300} 
                        width={400} 
                        initialMovementData={initialisationData}  
                        x={positionState["position"].x * 10} 
                        y={positionState["position"].z * 10} 
                        playerId={positionState.playerId} 
                    />
                    
                </Grid>
                <Grid item xs={12} md={4} container alignItems="center" justifyContent="center">
                    <Grid item xs={12}>
                        <ServerList monitorUrl={process.env.REACT_APP_MONITOR_SERVER} setOpen={setOpenStats}/>
                    </Grid>
                    <Grid item xs={12}>
                        <RoomSwitcher websocket={websocket} url={process.env.REACT_APP_MOVEMENT_SERVER} monitorUrl={process.env.REACT_APP_MONITOR_SERVER}/>
                    </Grid>
                    <Grid item xs={12}>
                        <RoomAvatars/>
                    </Grid>
                </Grid>
            </Grid>
            )
        }

     
           { showConsole && <NewWindow>
                <ActivityConsole />
            </NewWindow>}
        {
            websocket && (
                <><AvatarUpdaterModal open={open} setOpen={setOpen} webSocketClient={websocket} /><ServerStatsModel open={openStats} setOpen={setOpenStats} /></>
            )
        }    
    </div>
  );
}

export default App;
