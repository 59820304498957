import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setCurrentSelected } from "../../store/Reducers/Players";
import Select from "@mui/material/Select";
import { MenuItem } from "@mui/material";

export const RoomAvatars: React.FC = () => {
    const { avatarsInRoom } = useAppSelector(state => state.rooms);
    const [selectedAvatar, setSelectedAvatar] = useState("");
    const dispatch = useAppDispatch();

    const handleSelectChange = (event: any) => {
        const selectedPlayerId = event.target.value;
        const selectedAvatar = avatarsInRoom.find(avatar => avatar.playerId === selectedPlayerId);
        if (selectedAvatar) {
            dispatch(setCurrentSelected(selectedAvatar));
            setSelectedAvatar(selectedPlayerId);
        }
    };

    return (
        <div className="card dropDownCard">
            <div>
                <h4 style={{ margin: 0, textAlign: 'left', color: 'white' }}>Members:</h4>
                <Select
                    size="small"
                    value={selectedAvatar}
                    fullWidth
                    onChange={handleSelectChange}
                    displayEmpty
                >
                    <MenuItem value="" disabled>
                        Please Select
                    </MenuItem>
                    {
                        avatarsInRoom.map((avatar, index) => (
                            <MenuItem
                                key={`avatarsInRoom${index}`}
                                value={avatar.playerId}
                            >
                                {avatar.displayName}
                            </MenuItem>
                        ))
                    }
                </Select>
            </div>
        </div>
    );
}
