import TextField from '@mui/material/TextField';
import { useDispatch } from 'react-redux';
import React, { useState } from 'react';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { useAppSelector } from '../../store/hooks';
import { setNewMessage } from '../../store/Reducers/Messages';
import { setUserMessages } from '../../store/Reducers/Players';
import { WebSocketClient } from '../Comms/WebSocketClient';
import { getTimestamp } from '../Comms/Timestamp';

type Props = {
    websocket: WebSocketClient,
    setShowParties: Function,
    showParties: boolean
}

const ChatInput: React.FC<Props> = ({websocket,showParties, setShowParties}) => {

    const { newMessage } = useAppSelector((state) => state.messages)
    const {playerLoggedIn, userMessages} = useAppSelector(state => state.players)
    const [showEmojis, setShowEmojis] = useState(false)
    const [cursorPosition, setCursorPosition] = useState(0);

    const dispatch = useDispatch()

    // post the message to the server
    function sendMessage(message: string){

        let chatMessage = {
            from: playerLoggedIn.displayName,
            body: message,
            timestamp: getTimestamp(),
            playerId: playerLoggedIn.playerId
        }

        websocket?.publishToGlobalChat(chatMessage)
      
    }


    // prepare the message guid and add the message to the user messages, this will have to change when we have usernames
    function send(){
        sendMessage(newMessage)
        let messageHistory: Object[] = [...userMessages]
        messageHistory.push({msg: newMessage})
        dispatch(setUserMessages(messageHistory))
        dispatch(setNewMessage(""))
        setCursorPosition(0)
    }

    // listen for return to send message
    function handleKeyDown(event: any){
        if (event.key === 'Enter') {
            send()
            dispatch(setNewMessage(""));
        }
    }

    // add the emoji from the picker taking note of the cursor position so that the emoji doesnt wipe out the text
    const addEmoji = (emoji: any) => {
        const start = newMessage.substring(0, cursorPosition);
        const end = newMessage.substring(cursorPosition);
        const updatedMessage = start + emoji.native + end;
        dispatch(setNewMessage(updatedMessage));
        setCursorPosition(cursorPosition + emoji.native.length);
    };

    

    return (
       
        <div className='chat-input'>
            
            <TextField 
                onKeyDown={(e) => handleKeyDown(e)} 
                id="outlined-basic" 
                label="Type here.." 
                variant="outlined" 
                value={newMessage} 
                onChange={(e) => {
                    console.log("Dispatching message: "+ e.target.value)
                    dispatch(setNewMessage(e.target.value))}}
                fullWidth
                color='error'
                InputLabelProps={{style: { color: 'white' }}}
            /> 
            <button className="emoji-button" style={{marginLeft: 0}} onClick={(e) => { e.stopPropagation();setShowEmojis(!showEmojis)}}>
                {"\u{1F929}"}
            </button>
            <div className='emoji-picker'>
            {
                showEmojis ? <Picker 
                                data={data} 
                                onEmojiSelect={(emoji: any) => addEmoji(emoji)} onClickOutside={() => setShowEmojis(!showEmojis)}
                                theme='light'
                                /> : null
            }
            </div>
            <br/>
        </div>
        
    )
}

export default ChatInput;