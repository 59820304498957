export enum PartyAccessType {
    PRIVATE = "PRIVATE",
    PUBLIC = "PUBLIC"
}

export enum PartyPersonAudioStatus {
    DEFAULT = "DEFAULT",
    MUTED = "MUTED",
    SOLO = "SOLO"
}

export enum PartyPersonJoinStatus {
    INVITED = "INVITED",
    PENDING_APPROVAL = "PENDING_APPROVAL",
    JOINED = "JOINED",
    REJECTED_INVITE = "REJECTED_INVITE",
    KICKED = "KICKED"
}

export enum PartyCallStatus {
    NOT_CONNECTED = "NOT_CONNECTED",
    CONNECTED = "CONNECTED",
    DISCONNECTED = "DISCONNECTED"
}

export enum PartyStatus {
    NOT_STARTED = "NOT_STARTED",
    STARTED = "STARTED",
    ENDED = "ENDED"
}

export enum AcceptStatus {
    ACCEPT_REQUEST,
    REJECT_REQUEST,
    ACCEPT_INVITE,
    REJECT_INVITE
}

export enum PartyListMessageType {
    JOIN,
    LEAVE,
    END
}

export enum PlayerListOptions {
    KICK,
    MUTE,
    SOLO
}

export type PartyPerson = {
    playerId: string,
    audioStatus: PartyPersonAudioStatus,
    joinStatus: PartyPersonJoinStatus,
    partyPersonPeerId: string | null
    partyPersonCallStatus: PartyCallStatus
}


export type PartyInfo = {
    timestamp: string,
    partyId: string,
    hostPlayerId: string | null,
    partyName: string,
    accessType: PartyAccessType,
    partyPeople: PartyPerson[],
    partyStatus: PartyStatus,
    hostPeerId: string,
    hostPartyCallStatus: PartyCallStatus,
    partyActivities: PartyActivity[]
}

export type PartyMessage = {
    fromPlayerId: string,
    toPlayerId: string | null,
    partyInfo: PartyInfo,
    fromPlayerToPeerId: string | null,
    textMessage: string | null
    sessionId: string | null
}

export type PartyActivity = {
    activityId: string,
    activityName: string,
    fromSessionId: string
}