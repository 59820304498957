import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { end, getAllParties, joinRequestSend, leave } from "./RestCalls";
import { PartyMessage, PartyInfo, PartyStatus, PartyListMessageType } from "./PartyTypes";
import { WebSocketClient } from "../../Comms/WebSocketClient";
import { setAvailableParties } from "../../../store/Reducers/party";
import { PlayerInviteModal } from "./PlayerInviteModal";
import { PlayerListModal } from "./PlayerListModal";

type Props = {
    websocket: WebSocketClient
}

export const PartyList: React.FC<Props> = ({ websocket }) => {

    const { availableParties } = useAppSelector(state => state.party)
    const { playerLoggedIn } = useAppSelector(state => state.players)
    const [partyName, setPartyName] = useState("")
    const [open, setOpen] = useState(false)
    const [openPlayerList, setOpenPlayerList] = useState(false)
    const [selectedParty, setSelectedParty] = useState(0)
    const dispatch = useAppDispatch();

    const sendRequest = (index: number, type: PartyListMessageType) => {
        let party: PartyInfo = availableParties[index]
        let partyMessage: PartyMessage = {
            fromPlayerId: playerLoggedIn.playerId,
            toPlayerId: "",
            partyInfo: party,
            fromPlayerToPeerId: "",
            textMessage: "",
            sessionId: websocket.getSessiondId()
        }
        switch(type){
          case PartyListMessageType.END:
            end(partyMessage);
            break;
          case PartyListMessageType.JOIN:
            joinRequestSend(partyMessage);
            break;
          case PartyListMessageType.LEAVE:
            leave(partyMessage)
            break;
          default:
            console.log("no type given")
            break;
        }
        getParties()
    }

    const getParties = () => {
      getAllParties().then(res => {
          console.log(res)
          if (res.data) {
              dispatch(setAvailableParties(JSON.parse(res.data)))
          }
      })
    };

    const isAlreadyJoined = (playerId:string, index: number): boolean => {
      let isMember =  false  
      availableParties[index].partyPeople.forEach(person => {
        if(person.playerId === playerId) isMember = true
      })
      console.log("Is Member: " + isMember)
      return !isMember
    }

    return (
        <div>
            <ul>
                {
                    availableParties.map((party, index) => {
                        return party.partyStatus != PartyStatus.ENDED && (
                            <li key={party.partyId} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: '23%', marginBottom: '5px' }}>
                              <span style={{color: 'white'}}>
                                {party.partyName}
                              </span>
                              {
                                party.hostPlayerId === playerLoggedIn.playerId ? 
                                <div style={{ display: "flex", gap: "10px" }}>
                                  <button
                                  className="party-btn"
                                  onClick={() => {
                                    setSelectedParty(index)
                                    setOpenPlayerList(true)
                                  }}>
                                  Members
                                </button>
                                <button
                                  className="party-btn"
                                  onClick={() => {
                                    setSelectedParty(index)
                                    setPartyName(party.partyName)
                                    setOpen(true)
                                  }}>
                                  Invite
                                </button>
                                <button
                                  className="party-btn"
                                  onClick={() => sendRequest(index, PartyListMessageType.END)}>
                                  END
                                </button>
                              </div>
                               : 
                              <div style={{ display: "flex", gap: "10px" }}>
                                <button
                                  className={`${!isAlreadyJoined(playerLoggedIn.playerId, index) ? 'disabled-btn' : 'party-btn'}`}
                                  onClick={() => sendRequest(index, PartyListMessageType.JOIN)}
                                  disabled={!isAlreadyJoined(playerLoggedIn.playerId, index)}
                                  >
                                  Join
                                </button>
                                <button
                                  className={`${isAlreadyJoined(playerLoggedIn.playerId, index) ? 'disabled-btn' : 'party-btn'}`}
                                  onClick={() => sendRequest(index, PartyListMessageType.LEAVE)}
                                  disabled={isAlreadyJoined(playerLoggedIn.playerId, index)}
                                  >
                                  Leave
                                </button>
                              </div>
                              }
                            </li>
                          );
                    })
                }
            </ul>
            <PlayerInviteModal open={open} setOpen={setOpen} name={partyName} websocket={websocket} party={availableParties[selectedParty]}/>
            { availableParties[selectedParty] && (<PlayerListModal open={openPlayerList} setOpen={setOpenPlayerList} index={selectedParty} websocket={websocket} />)}
        </div>
    )
}