import { useEffect, useState } from "react"
import { WebSocketClient } from "./WebSocketClient"
import { useAppSelector, useAppDispatch } from "../../store/hooks"
import { setAvatarsInRoom, setRooms } from "../../store/Reducers/Rooms"
import { AvatarDefinition } from "../../store/Reducers/Players"
import Select from "@mui/material/Select"
import MenuItem from '@mui/material/MenuItem';
import { setWebSocket } from "../../store/Reducers/Connection"
import { resetMovements } from "../../store/Reducers/Movements"
import Rooms from '../../Rooms.json'
import { setServers } from "../../store/Reducers/Servers"
import { Server } from "../Servers/ServerList"
import { extractIpFromWsUrl } from "../Servers/ExtractIp"

type Props = {
  websocket: WebSocketClient,
  url: string | undefined,
  monitorUrl: string | undefined
}
export const RoomSwitcher: React.FC<Props> = ({ websocket, url, monitorUrl }) => {

  const { rooms } = useAppSelector(state => state.rooms)
  const {avatarData} = useAppSelector(state => state.players)
  const [selectedRoom, setSelectedRoom] = useState<string>("")
  const dispatch = useAppDispatch()


  async function getRooms() {
    try {
      const response = await fetch(url + "/api/monitor/rooms", {
        method: 'GET'
      });

      if (!response.ok) {
        throw new Error(`Error! status: ${response.status}`);
      }

      const data: string[] = await response.json();

      dispatch(setRooms(data))
      return { data };
    } catch (error) {
      let errorMessage = 'An unknown error occurred';
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      return { data: null, error: errorMessage };
    }
  }

  async function getAvatarsInRoom(room: string) {
    try {
      const response = await fetch(url + "/api/monitor/localroomavatars/" + room, {
        method: 'GET'
      });

      if (!response.ok) {
        throw new Error(`Error! status: ${response.status}`);
      }

      const data: AvatarDefinition[] = await response.json();

      dispatch(setAvatarsInRoom(data))
      return { data };
    } catch (error) {
      let errorMessage = 'An unknown error occurred';
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      return { data: null, error: errorMessage };
    }
  }

  async function getServersByRoom(room: string) {
    try {
      const response = await fetch(monitorUrl + "/monitor/roomservers/" + room, {
        method: 'GET'
      });

      if (!response.ok) {
        throw new Error(`Error! status: ${response.status}`);
      }

      const data: Server[] = await response.json();
      let serverList: string[] = []
            
      data.forEach(server => {
          console.log(server)
          const ip = extractIpFromWsUrl(server.serverUrl)
          if(ip) serverList.push(ip)
      })

      dispatch(setServers(serverList))
      return { data };
    } catch (error) {
      let errorMessage = 'An unknown error occurred';
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      return { data: null, error: errorMessage };
    }
  }

  useEffect(() => {
    getRooms().then(response => {
      console.log(response)
    })
  }, [])


  const handleSelectChange = async (event: any) => {
    const selectedValue = JSON.parse(event.target.value);
    const selectedRoom = selectedValue.room;
    const selectedIndex = selectedValue.index;
    setSelectedRoom(event.target.value)
    const response = await getAvatarsInRoom(selectedRoom);
    websocket.disconnect();
    dispatch(resetMovements([]))
    if(process.env.REACT_APP_WEBSOCKET_SERVER){
      dispatch(setWebSocket(new WebSocketClient(process.env.REACT_APP_WEBSOCKET_SERVER, selectedRoom)))
    }
    
    getServersByRoom(selectedRoom)
  };

  return (
    <div className="card dropDownCard serverGridElements">
      <div>
        <h4 style={{ margin: 0, textAlign: 'left', color: 'white' }}>Rooms:</h4>
        <Select
          value={selectedRoom}
          displayEmpty
          fullWidth
          size="small"
          onChange={handleSelectChange}
        >
          <MenuItem value="" disabled>
            Please Select
          </MenuItem>
          {
            rooms.map((room, index) => {
              return <MenuItem key={`room${index}`} value={JSON.stringify({ room: room, index })}>{room}</MenuItem>
            })
          }
        </Select>
      </div>
    </div>
  )
}