import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { PartyInfo, PartyMessage } from "../../components/Chat/PartyComponents/PartyTypes";

export interface Message  {
    from:string,
    guid: string,
    msg: string, 
    timestamp: string,
    partyInfo?: PartyMessage,
    responded?: boolean
}

interface MessageState {
    messages: string[],
    pause: boolean,
    messageHistory: Message[],
    newMessage: string,
    unityMessage: string
}

const initialState: MessageState = {
    messages: ["---opening comms---"],
    pause: false,
    messageHistory: [],
    newMessage:"",
    unityMessage: ""
}

export const messageSlice = createSlice({
    name: 'messages',
    initialState,
    reducers: {
        setMessages: (state, action: PayloadAction<string>) => {
            state.messages.push(action.payload)
            if (state.messages.length > 200) {
                const messagesToRemove = state.messages.length - 200;
                state.messages.splice(0, messagesToRemove);
            }
        },
        setPause: (state, action: PayloadAction<boolean>) => {
            state.pause = action.payload
        },
        setMessageHistory: (state, action) => {
            state.messageHistory = action.payload
        },
        setNewMessage: (state, action) => {
            state.newMessage = action.payload
        },
        setUnityMessage: (state, action) => {
            state.unityMessage = action.payload
        }
    }
})

export const { setMessages, setPause, setMessageHistory, setNewMessage, setUnityMessage } = messageSlice.actions

export const selectMessages = (state: RootState) => state.messages

export default messageSlice.reducer