import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useSpring, animated } from '@react-spring/web';
import { Avatar } from '@readyplayerme/visage';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setUnityMessage } from '../../../store/Reducers/Messages';
import { PartyInfo, PartyMessage, PlayerListOptions } from './PartyTypes';
import { WebSocketClient } from '../../Comms/WebSocketClient';
import { getAllParties, kick, mute, solo } from './RestCalls';
import { setAvailableParties } from '../../../store/Reducers/party';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, Typography } from '@mui/material';

interface FadeProps {
    children: React.ReactElement;
    in?: boolean;
    onClick?: any;
    onEnter?: (node: HTMLElement, isAppearing: boolean) => void;
    onExited?: (node: HTMLElement, isAppearing: boolean) => void;
    ownerState?: any;
}

export const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(props, ref) {
    const {
        children,
        in: open,
        onClick,
        onEnter,
        onExited,
        ownerState,
        ...other
    } = props;
    const style = useSpring({
        from: { opacity: 0 },
        to: { opacity: open ? 1 : 0 },
        onStart: () => {
            if (open && onEnter) {
                onEnter(null as any, true);
            }
        },
        onRest: () => {
            if (!open && onExited) {
                onExited(null as any, true);
            }
        },
    });

    return (
        <animated.div ref={ref} style={style} {...other}>
            {React.cloneElement(children, { onClick })}
        </animated.div>
    );
});

export type StyleType = {
    position: string;
    top: string;
    left: string;
    transform: string;
    width: string;
    boxShadow: number;
    p: number;
};

export const style: StyleType = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '20%',
    boxShadow: 24,
    p: 4,
};

type Props = {
    open: boolean,
    setOpen: Function,
    index: number,
    websocket: WebSocketClient
}
export const PlayerListModal: React.FC<Props> = ({ open, setOpen, index, websocket }) => {

    const handleClose = () => setOpen(false);
    const { availableParties } = useAppSelector(state => state.party)
    const dispatch = useAppDispatch()

    const handleKick = (playerId: string, type: PlayerListOptions) => {
        let party: PartyInfo = availableParties[index]
        let partyMessage: PartyMessage = {
            fromPlayerId: playerId,
            toPlayerId: playerId,
            partyInfo: party,
            fromPlayerToPeerId: "",
            textMessage: "",
            sessionId: websocket.getSessiondId()
        }
        switch (type) {
            case PlayerListOptions.KICK:
                kick(partyMessage).then(res => {
                    console.log(res)
                    getParties()
                })
                break;
            case PlayerListOptions.MUTE:
                mute(partyMessage).then(res => {
                    console.log(res)
                    getParties()
                })
                break;
            case PlayerListOptions.SOLO:
                solo(partyMessage).then(res => {
                    console.log(res)
                    getParties()
                })
                break;
            default:
                console.log("No Options Passed In")
                break;
        }

    }

    const getParties = () => {
        getAllParties().then(res => {
            console.log(res)
            if (res.data) {
                dispatch(setAvailableParties(JSON.parse(res.data)))
            }
        })
    };

    return (
        <div>
            <Modal
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        TransitionComponent: Fade,
                    },
                }}
            >
                <Fade in={open}>
                    <Box component="div" className='card dropDownCard party-people-modal-list' sx={style}>
                        <h3 style={{ textAlign: 'center' }}>Party People</h3>

                        {
                            availableParties[index].partyPeople.length > 0 && (availableParties[index].partyPeople.map(person => {
                                return <><Accordion><AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    {person.playerId}
                                    <button
                                        className='party-btn'
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            handleKick(person.playerId, PlayerListOptions.KICK);
                                        }}
                                    >
                                        Kick
                                    </button>
                                    <button
                                        className='party-btn'
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            handleKick(person.playerId, PlayerListOptions.MUTE);
                                        }}
                                    >
                                        Mute
                                    </button>
                                    <button
                                        className='party-btn'
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            handleKick(person.playerId, PlayerListOptions.SOLO);
                                        }}
                                    >
                                        Solo
                                    </button>
                                </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            Audio Status: {person.audioStatus}<br></br>
                                            Join Status: {person.joinStatus}<br></br>
                                            Call Status: {person.partyPersonCallStatus}
                                        </Typography>
                                    </AccordionDetails></Accordion></>
                            })
                            )}

                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}