import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useSpring, animated } from '@react-spring/web';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { WebSocketClient } from '../Comms/WebSocketClient';
import { setPlayerLoggedin } from '../../store/Reducers/Players';

interface FadeProps {
  children: React.ReactElement;
  in?: boolean;
  onClick?: any;
  onEnter?: (node: HTMLElement, isAppearing: boolean) => void;
  onExited?: (node: HTMLElement, isAppearing: boolean) => void;
  ownerState?: any;
}

export const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null as any, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null as any, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

export type StyleType = {
    position: string;
    top: string;
    left: string;
    transform: string;
    width: string;
    boxShadow: number;
    p: number;
  };

export const style: StyleType = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  boxShadow: 24,
  p: 4,
};

type Props = {
    open: boolean,
    setOpen: Function,
    webSocketClient: WebSocketClient
}
export const AvatarUpdaterModal: React.FC<Props> = ({open,setOpen,webSocketClient}) =>  {
  
  const handleClose = () => setOpen(false);
  const { playerLoggedIn } = useAppSelector(state => state.players)
  const dispatch = useAppDispatch()

    const  setDisplayName =  (event: React.ChangeEvent<HTMLInputElement>) => {
        const newDisplayName = event.target.value;
        const updatedPlayer = {
          ...playerLoggedIn,
          displayName: newDisplayName,
        };
        dispatch(setPlayerLoggedin(updatedPlayer));
    }

    const  setAvatarUrl =  (event: React.ChangeEvent<HTMLInputElement>) => {
        const newAvatar = event.target.value;
        const updatedPlayer = {
          ...playerLoggedIn,
          avatarUrl: newAvatar,
        };
        dispatch(setPlayerLoggedin(updatedPlayer));
    }

    const updateAvatarClick = () => {
        webSocketClient.publishObjToUpdateAvatarTopic(playerLoggedIn)
        setOpen(false)
    }

  return (
    <div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={open}>
          <Box component="div" sx={style}>
          <div  className="card dropDownCard" style={{width:'100%', height: '90%'}}>
                <h3 style={{margin: 0}}>Current Selected Avatar</h3>
                <div className={"playerAvatarDetails"} style={{ width: "100%", position: 'relative'}}>
                    PlayerID:<br/>
                    <input disabled className="customInput" value={playerLoggedIn.playerId}  /> <br />
                    Display Name:<br />
                    <input className="customInput" value={playerLoggedIn.displayName} readOnly={false}  onChange={ setDisplayName }/> <br />
                    Avatar URL:<br />
                    <input className="customInput"  value={playerLoggedIn.avatarUrl} readOnly={false} onChange={ setAvatarUrl } /> <br /><br />
                    <button className="customButton" onClick={updateAvatarClick}>Update</button>
                </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}