import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type serverData = {
    publicIp: string,
    privateIp: string,
    cpuUsage: number,
    diskIo: number,
    networkIn: number,
    networkOut: number,
    transferQuota: number
}

interface ServersState{
    servers: string[],
    selectedServer: string
    serverData: serverData
}

const initialState: ServersState = {
    servers: ["192.168.0.1","192.168.0.2","192.168.0.3","192.168.0.4",],
    selectedServer: "",
    serverData: {publicIp: "192.168.0.1",privateIp: "XXX.XXX.XXX.XXX", cpuUsage: 80, diskIo: 1234, networkIn: 10, networkOut: 10, transferQuota: 100}
}

export const serversSlice = createSlice({
    name: 'servers',
    initialState,
    reducers: {
        setServers: (state, action: PayloadAction<string[]>) => {
            state.servers = action.payload
        },
        setSelectedServer: (state, action: PayloadAction<string>) => {
            state.selectedServer = action.payload
        },
        setServerData: (state, action: PayloadAction<serverData>) => {
            state.serverData = action.payload
        }
    }
})

export const { setServers, setSelectedServer, setServerData } = serversSlice.actions
export default serversSlice.reducer 