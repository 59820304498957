import React from "react";
import { badWords } from "./badwordlist";
import { AcceptStatus, PartyMessage } from "../PartyComponents/PartyTypes";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { WebSocketClient } from "../../Comms/WebSocketClient";
import { inviteAccept, inviteReject, joinRequestAccept, joinRequestReject } from "../PartyComponents/RestCalls";
import { setMessageHistory } from "../../../store/Reducers/Messages";

export type msg = {
    msg: string,
    from: string,
    timestamp: string
    partyMessage?: PartyMessage,
    responded?: boolean
}

type chatBubbleProps = {
    index: number,
    msg: msg,
    websocket: WebSocketClient
}

const ChatBubble: React.FC<chatBubbleProps> = ({index,msg, websocket}) => {
    
    const { playerLoggedIn } = useAppSelector(state => state.players)
    const { messageHistory } = useAppSelector(state => state.messages)
    const dispatch = useAppDispatch()
    var Filter = require('bad-words'),
    filter = new Filter();
    badWords.forEach(word => {
        filter.addWords(word)
    })
    var location = window.location;
    var baseUrl = location.protocol + "//" + location.hostname + (location.port ? ":" + location.port : "");

    const isEmoji = (str:string) => {
        const regex = /[\p{Emoji}]/u;
        return regex.test(str);
    }
    
    const cleanMessage = (msg: string) => {
        if(msg != null){
            return isEmoji(msg) ? msg : filter.clean(msg);
        }
        
    }

    const formatListItem = (msg: msg) => {
        return formatUnityMessage(msg)
    }

    const formatUnityMessage = (msg: msg) => {
        if(msg.msg.includes("has requested to join")){
            return <li className="chat-bubble" style={{ fontWeight: 'bold'}}>
                <span style={{color: 'rgb(100,234,255)'}}>{cleanMessage(msg.from.length > 0 ? msg.from : "Guest")}</span>
                <span style={{color: 'lightgray'}}> said:</span> {cleanMessage(msg.msg)}
                 {
                    msg.responded ? null : <div> <span style={{color: 'green'}}>Would you like to accept?
                    <button onClick={() => handleInviteRequest(msg.partyMessage, AcceptStatus.ACCEPT_REQUEST)}>Yes</button>
                    <button onClick={() => handleInviteRequest(msg.partyMessage, AcceptStatus.REJECT_REQUEST)}>No</button>
                    </span></div>
                 }
                </li>
        } else if (msg.msg.includes("You have been invited to")){
            return <li className="chat-bubble" style={{ fontWeight: 'bold'}}>
                <span style={{color: 'rgb(100,234,255)'}}>{cleanMessage(msg.from.length > 0 ? msg.from : "Guest")}</span>
                <span style={{color: 'lightgray'}}> said:</span> {cleanMessage(msg.msg)}
                 {
                    msg.responded ? null : <div> <span style={{color: 'green'}}>Would you like to accept?
                    <button onClick={() => handleInviteRequest(msg.partyMessage, AcceptStatus.ACCEPT_INVITE)}>Yes</button>
                    <button onClick={() => handleInviteRequest(msg.partyMessage, AcceptStatus.REJECT_INVITE)}>No</button>
                    </span></div>
                 }
                </li>
        } else {
            return<li className="chat-bubble" style={{ fontWeight: 'bold'}}>
            <span style={{color: 'rgb(100,234,255)'}}>{cleanMessage(msg.from.length > 0 ? msg.from : "Guest")}</span>
             <span style={{color: 'lightgray'}}> said:</span> {cleanMessage(msg.msg)}</li>
        }
    }
    
    const handleInviteRequest = (partyMessageIncoming: PartyMessage | undefined, type: AcceptStatus) => {
        if(partyMessageIncoming == undefined){
            console.log("Party info is undefined")
            return
        } else {
            console.log(partyMessageIncoming)
            let partyMessage: PartyMessage = {
                fromPlayerId: partyMessageIncoming.fromPlayerId,
                toPlayerId: partyMessageIncoming.fromPlayerId,
                partyInfo: partyMessageIncoming.partyInfo,
                fromPlayerToPeerId: "",
                textMessage: "",
                sessionId: websocket.getSessiondId()
            }
            switch(type){
                case AcceptStatus.ACCEPT_REQUEST:
                    joinRequestAccept(partyMessage).then(res => {
                        console.log(res.data)
                    })
                    break;
                case AcceptStatus.REJECT_REQUEST:
                    joinRequestReject(partyMessage).then(res => {
                        console.log(res.data)
                    })
                    break;
                case AcceptStatus.ACCEPT_INVITE:
                    partyMessage.fromPlayerId = playerLoggedIn.playerId
                    inviteAccept(partyMessage).then(res => {
                        console.log(res.data)
                    })
                    break;
                case AcceptStatus.REJECT_INVITE:
                    partyMessage.fromPlayerId = playerLoggedIn.playerId
                    inviteReject(partyMessage).then(res => {
                        console.log(res.data)
                    })
                    break;
                default:
                    console.log("no Status Passed into handle invite Request")
                    break;
            }
            updateMessageHistory(index, true)
        }        
    }

    const updateMessageHistory = (index: number, responded: boolean) => {
        let updatedState = messageHistory.map((message: any, i: number) =>
            i === index ? { ...message, responded } : message
        )
        dispatch(setMessageHistory(updatedState));
    };
    

    return(
        <div>
            {
                formatListItem(msg)
            }
        </div>
    )
}

export default ChatBubble;