import React, { useEffect, useRef, useState } from "react";
import { getAllParties, inviteAccept, joinRequestAccept } from "./RestCalls";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { WebSocketClient } from "../../Comms/WebSocketClient";
import { setAutoAccept, setAvailableParties } from "../../../store/Reducers/party";
import { IMessage } from "@stomp/stompjs";
import { setMessages, setUnityMessage } from "../../../store/Reducers/Messages";
import { SetupPartyModal } from "./SetupPartyModal";
import { PartyMessage } from "./PartyTypes";

type Props = {
    websocket: WebSocketClient,
    setShowParties: Function,
    setHeading: Function
}

export const MessageHandler: React.FC<Props> = ({ websocket, setShowParties,setHeading }) => {

    const [showSetupPartyModal, setShowSetupPartyModal] = useState(false)
    const { autoAccept } = useAppSelector(state => state.party)
    const isCheckedRef = useRef(autoAccept)
    const dispatch = useAppDispatch();

    useEffect(() => {
        isCheckedRef.current = autoAccept
    },[autoAccept])

    useEffect(() => {
        websocket.addUserQueuePartySubscription(handleUserQueuePartyMessage)
    },[])

    const handleUserQueuePartyMessage = (message:IMessage) => {
        let data = JSON.parse(message.body)
        dispatch(setMessages(message.body))
        let responded = false
        console.log("Checking is Checked: " + isCheckedRef.current)
        if(isCheckedRef.current){
            let partyMessage: PartyMessage = {
                fromPlayerId: data.toPlayerId,
                toPlayerId: data.fromPlayerId,
                partyInfo: data.partyInfo,
                fromPlayerToPeerId: "",
                textMessage: "",
                sessionId: websocket.getSessiondId()
            }
            if(data.textMessage.includes("has requested to join")){
                partyMessage.fromPlayerId = data.fromPlayerId
                partyMessage.toPlayerId = data.fromPlayerId
                joinRequestAccept(partyMessage).then(res => {
                    console.log(res.data)
                })
                responded = true
            }
            if(data.textMessage.includes("You have been invited to")){
                inviteAccept(partyMessage).then(res => {
                    console.log(res.data)
                })
                responded = true
            }
        }      
        dispatch(setUnityMessage(JSON.stringify({type: "unityMessage", msg: data, responded: responded})))  
        getAllParties().then(res => {
            console.log(res)
            if (res.data) {
                dispatch(setAvailableParties(JSON.parse(res.data)))
            }
        })
    }

    const getParties = () => {
        getAllParties().then(res => {
            console.log(res)
            if (res.data) {
                dispatch(setAvailableParties(JSON.parse(res.data)))
            }
        }).then(() => {
            setShowParties(true)
            setHeading("Parties")
        })
    };

    const handleShowChat = () => {
        setShowParties(false)
        setHeading("Live Chat")
    }

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setAutoAccept(event.target.checked))
    };

    return (
        <div className="party-cntrl-container">
            <SetupPartyModal websocket={websocket} open={showSetupPartyModal} setOpen={setShowSetupPartyModal}/>
            <div style={{ position: 'absolute', margin: '2px'}}>
                <button className='party-btn' onClick={() => setShowSetupPartyModal(true)}>Start Party</button>
                <button className='party-btn' onClick={getParties}>Parties</button>
                <button className='party-btn' onClick={handleShowChat}>Chat</button>
                <input 
                    type="checkbox" 
                    id="AutoAccept" 
                    name="AutoAccept"
                    checked={autoAccept}
                    onChange={handleCheckboxChange}
                    style={{
                        marginLeft: -140,
                        padding: 0,

                    }}
                    />
                <label htmlFor="AutoAccept" style={{color: 'white', marginLeft: -140, width: '2%'}}>AutoAccept</label>
            </div>

        </div>
    )
}