import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useSpring, animated } from '@react-spring/web';
import { useAppSelector } from '../../../store/hooks';
import { MenuItem, Select } from '@mui/material';
import { invite } from './RestCalls';
import { PartyCallStatus, PartyInfo, PartyMessage, PartyPersonAudioStatus, PartyPersonJoinStatus } from './PartyTypes';
import { WebSocketClient } from '../../Comms/WebSocketClient';

interface FadeProps {
  children: React.ReactElement;
  in?: boolean;
  onClick?: any;
  onEnter?: (node: HTMLElement, isAppearing: boolean) => void;
  onExited?: (node: HTMLElement, isAppearing: boolean) => void;
  ownerState?: any;
}

export const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null as any, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null as any, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

export type StyleType = {
    position: string;
    top: string;
    left: string;
    transform: string;
    width: string;
    boxShadow: number;
    p: number;
  };

export const style: StyleType = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '20%',
  boxShadow: 24,
  p: 4,
};

type Props = {
    open: boolean,
    setOpen: Function,
    name: string,
    party: PartyInfo,
    websocket: WebSocketClient
}
export const PlayerInviteModal: React.FC<Props> = ({open,setOpen,name, party, websocket}) =>  {

  const { avatarData, playerLoggedIn } = useAppSelector(state => state.players)
  const [selectedOption, setSelectedOption] = React.useState<string>("");

  const handleSelectChange = (event:any) => {
      setSelectedOption(event.target.value);
  };
  const handleInvite = () => {
    const personExists = party.partyPeople.some(person => person.playerId === selectedOption);
  
    if (!personExists) {
      const newPartyPeople = [
        {
          playerId: selectedOption,
          audioStatus: PartyPersonAudioStatus.DEFAULT,
          joinStatus: PartyPersonJoinStatus.INVITED,
          partyPersonPeerId: null,
          partyPersonCallStatus: PartyCallStatus.NOT_CONNECTED,
        }
      ];
  
      let updatedParty = {
        ...party,
        partyPeople: newPartyPeople,
      };
  
      let partyMessage: PartyMessage = {
        fromPlayerId: playerLoggedIn.playerId,
        toPlayerId: "",
        partyInfo: updatedParty,
        fromPlayerToPeerId: "",
        textMessage: "",
        sessionId: websocket.getSessiondId(),
      };
  
      invite(partyMessage).then(res => {
        console.log(res.data);
      });
    } else {
      console.log("Person is already in the party");
    }
  };
  


  return (
    <div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={open}>
          <Box component="div"  className='card dropDownCard' sx={style}>
                <div>
                  <h4 style={{margin: 0}}>{name}</h4>
                  <br></br><br></br>
                  <Select value={selectedOption}  size="small" displayEmpty fullWidth onChange={handleSelectChange}>
                            <MenuItem value="" disabled>
                                Please Select
                            </MenuItem>
                            {
                                avatarData.map((avatar, index) => {
                                    return <MenuItem key={`room${index}`} value={avatar.playerId}>{avatar.displayName}</MenuItem>
                                })
                            }
                    </Select>
                  <br></br><br></br>
                  <div style={{ display: 'flex', gap: '10px' }}>
                  <button
                      onClick={() => handleInvite()}
                      className="customButton"
                  >
                    Invite
                  </button>
                  <button
                      onClick={() => setOpen(false)}
                      className="customButton"
                  >
                    Cancel
                  </button>
                  </div>
                </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}