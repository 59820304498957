import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

export interface Movement {
    position: {
        x :number,
        y :number,
        z :number
    },
    velocity: {
        x :number,
        y :number,
        z :number
    },
    rotation: number,
    playerId: string
}

interface MovementsState{
    movements: Movement[]
}

const initialState: MovementsState = {
    movements: []
}

export const movementsSlice = createSlice({
    name: 'movement',
    initialState,
    reducers: {
        setMovement: (state, action: PayloadAction<Movement[]>) => {
            let oldState = [...state.movements];
            for (let item of action.payload) {
                const index = oldState.findIndex(element => element.playerId === item.playerId);
                if (index !== -1) {
                    oldState[index] = item;
                } else {
                    oldState.push(item);
                }
            }
            state.movements = oldState;
            return state;
        },
        resetMovements: (state, action: PayloadAction<[]>) => {
            state.movements = action.payload
        }
    }
})

export const {setMovement, resetMovements} = movementsSlice.actions
export const selectMovement = (state: RootState) => state.movements
export default movementsSlice.reducer