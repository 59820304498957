import { Select, MenuItem } from "@mui/material";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { serverData, setSelectedServer, setServerData, setServers } from "../../store/Reducers/Servers";
import { ServerDetails } from "./ServerDetails";
import { extractIpFromWsUrl } from "./ExtractIp";

type Props = {
    monitorUrl: string | undefined,
    setOpen: Function
}

export type Server = {
    serverId: string,
    serverName: string,
    serverUrl: string
}

export const ServerList: React.FC<Props> = ({monitorUrl, setOpen}) => {

    const { servers, selectedServer } = useAppSelector(state => state.servers)
    const dispatch = useAppDispatch()
    
    async function getServers(){
        try {
            const response = await fetch(monitorUrl + "/monitor/servers", {
              method: 'GET'
            });
        
            if (!response.ok) {
              throw new Error(`Error! status: ${response.status}`);
            }
        
            const data: Server[] = await response.json();
            
            let serverList: string[] = []
            
            data.forEach(server => {
                console.log(server)
                const ip = extractIpFromWsUrl(server.serverUrl)
                if(ip) serverList.push(ip)
            })

            dispatch(setServers(serverList))
            return { data };
          } catch (error) {
            let errorMessage = 'An unknown error occurred';
            if (error instanceof Error) {
            errorMessage = error.message;
            }
            return { data: null, error: errorMessage };
          }
    }

    async function getServer(server:string){
         try {
            let ip = server
            const response = await fetch(monitorUrl + `/monitor/server/${ip}/stats` , {
              method: 'GET'
            });
        
            if (!response.ok) {
              throw new Error(`Error! status: ${response.status}`);
            }
        
            const data: serverData = await response.json();
            // handle data here
            dispatch(setServerData(data))
            return { data };
          } catch (error) {
            let errorMessage = 'An unknown error occurred';
            if (error instanceof Error) {
            errorMessage = error.message;
            }
            return { data: null, error: errorMessage };
          }
    }

    useEffect(() => {
        getServers().then(response => {
            console.log(response)        
        })
    },[])

    const handleSelectChange = async (event:any) => {
        const newIp= event.target.value;
        dispatch(setSelectedServer(newIp))
        getServer(newIp)
    };

    const handleShowAll = () => {
        getServers()
    }
    
    return (
         <div className="card dropDownCard serverGridElements">
                <div>
                    <button
                        className="statsButton showButton"
                        onClick={handleShowAll}
                    >Show All</button>
                    <h4 style={{margin: 0, textAlign: 'left', color: 'white' }}>Servers:</h4>
                    <Select value={selectedServer}  size="small" displayEmpty fullWidth onChange={handleSelectChange}>
                            <MenuItem value="" disabled>
                                Please Select
                            </MenuItem>
                            {
                                servers.map((server, index) => {
                                    return <MenuItem key={`room${index}`} value={server}>{server}</MenuItem>
                                })
                            }
                    </Select>
                </div>
                <div>
                    <h4 style={{margin: 0, textAlign: 'left', color: 'white' }}>Server:</h4>
                    <ServerDetails setOpen={setOpen}/>
                </div>
     </div>
    )
}