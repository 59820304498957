import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import {generateUniqueID} from "web-vitals/dist/modules/lib/generateUniqueID";
import { InitialAvatarData } from "../../App";

export const thisPlayer:string = generateUniqueID()

export interface AvatarDefinition {
    playerId: string;
    displayName: string;
    avatarUrl: string;
}

interface userMessage {
    guid: string,
    mesg: string, 
    timestamp: string
}

interface PlayersState{
    playerLoggedIn: AvatarDefinition,
    currentSelected: AvatarDefinition,
    avatarData: AvatarDefinition[],
    userMessages: userMessage[]
}

const initialState: PlayersState = {
    playerLoggedIn: {playerId:thisPlayer, avatarUrl: "avatar-not-set.png", displayName: ""},
    currentSelected: {playerId:thisPlayer, avatarUrl: "avatar-not-set.png", displayName: ""},
    avatarData: [],
    userMessages: []
}

export const playersSlice = createSlice({
    name: 'players',
    initialState,
    reducers: {
        setPlayerLoggedin: (state, action: PayloadAction<AvatarDefinition>) => {
            state.playerLoggedIn = action.payload
        },
        setCurrentSelected: (state, action:PayloadAction<AvatarDefinition>) => {
            state.currentSelected = action.payload
        },
        setAvatarData: (state, action:PayloadAction<AvatarDefinition[]>) => {
            state.avatarData = action.payload
        },
        setUserMessages: (state, action) => {
            state.userMessages = action.payload
        }
    }
})

export const { setCurrentSelected, setPlayerLoggedin, setAvatarData, setUserMessages } = playersSlice.actions
export const selectCurrentSelected = (state: RootState) => state.players; 
export default playersSlice.reducer