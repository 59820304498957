import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AvatarDefinition } from "./Players";

interface RoomsState{
    rooms: string[],
    avatarsInRoom: AvatarDefinition[]
}

const initialState: RoomsState = {
    rooms: ["No Data"],
    avatarsInRoom: [{playerId:"No player Selected", avatarUrl: "avatar-not-set.png", displayName: "No Data"}]
}

export const roomsSlice = createSlice({
    name: 'rooms',
    initialState,
    reducers: {
        setRooms: (state, action: PayloadAction<string[]>) => {
            state.rooms = action.payload
        },
        setAvatarsInRoom: (state, action: PayloadAction<AvatarDefinition[]>) => {
            state.avatarsInRoom = action.payload
        }
    }
})

export const { setRooms, setAvatarsInRoom }  = roomsSlice.actions
export default roomsSlice.reducer