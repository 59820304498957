export function extractIpFromWsUrl(url: string): string | null {
    if (url.startsWith("ws://")) {
      const addressPart = url.substring(5);
      const colonIndex = addressPart.indexOf(':');
      if (colonIndex !== -1) {
        return addressPart.substring(0, colonIndex);
      }
    }
    return null;
}
