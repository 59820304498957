import { configureStore } from "@reduxjs/toolkit";
import Messages from "./Reducers/Messages";
import Connection from "./Reducers/Connection";
import Movements from './Reducers/Movements';
import Players from "./Reducers/Players";
import Rooms from "./Reducers/Rooms";
import Servers from "./Reducers/Servers";
import party from "./Reducers/party";

export const store = configureStore({
    reducer: {
        messages: Messages,
        connection: Connection,
        movements: Movements,
        players: Players,
        rooms: Rooms,
        servers: Servers,
        party: party
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
          serializableCheck: {
            ignoredActions: ['connection/setWebSocket'],
            ignoredPaths: ['connection.websocket']
          },
        }),
})

export type RootState = ReturnType<typeof store.getState>
export type AppStore = typeof store
export type AppDispatch = typeof store.dispatch